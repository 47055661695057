<template>
	<!-- 모바일버전 전체메뉴 -->
	<v-navigation-drawer
		v-model="model"
		app
		clipped
		temporary
		field
		right
		touchless
		width="320"
		class="drawer_box"
	>
		<!-- 로그인상태 -->
		<v-list-item class="login_state">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col
					cols="10"
					class="pa-0"
					@click="onUserClick"
				>
					<v-row class="ma-0">
						<v-col
							class="pa-0"
							cols="auto"
							align-self="center"
						>
							<v-img
								:src="require('@/assets/img/icon/icon_user.svg')"
								width="100%"
								contain
							/>
						</v-col>
						<v-col
							class="pa-0 pl-2"
							align-self="center"
						>
							<span v-if="AUTH_GET_USER_AUTH === 'GUEST'">로그인</span>
							<span v-else>
								안녕하세요!
								<strong>{{ AUTH_GET_USER.name }}</strong>
								님
							</span>
						</v-col>
					</v-row>
				</v-col>
				<v-col
					cols="2"
					class="pa-0 pl-3"
					align-self="center"
				>
					<v-btn
						icon
						text
						@click="toggleModel"
					>
						<v-icon color="#666">mdi-window-close</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-list-item>

		<v-divider></v-divider>

		<!-- 버튼메뉴(회원가입/동화톡톡/동화콜센터) -->
		<v-list-item class="pa-0 icon_menu_top">
			<v-row
				class="ma-4"
				align="center"
			>
				<v-col
					v-for="(menu, i) in menuListItems"
					:key="i"
					v-ripple
					cols="4"
					align="center"
					justify="center"
					class="pa-0 icon_menu_item"
					@click="onClick(menu)"
				>
					<v-img
						width="30"
						class="pb-1"
						:src="menu.icon"
						contain
					/>
					<span class="color_white px-2">{{ menu.title }}</span>
				</v-col>
			</v-row>
		</v-list-item>

		<!-- 버튼메뉴(최근본상품/빠른견적내기) -->
		<v-list-item class="pa-0 icon_menu_bottom">
			<v-row class="ma-4 justify-center">
				<v-col
					v-for="(menu, i) in floatingMenuListItems"
					:key="i"
					v-ripple
					cols="4"
					align="center"
					justify="center"
					class="pa-0 icon_menu_item"
					@click="onClick(menu)"
				>
					<v-img
						width="30"
						class="pb-1"
						:src="menu.icon"
						contain
					/>
					<span class="color_white px-2">{{ menu.title }}</span>
				</v-col>
			</v-row>
			<div
				id="naverChatBot"
				class="talk_banner_div"
				data-id="22278"
				style="display: none"
			></div>
		</v-list-item>

		<!-- 전체메뉴 -->
		<v-list class="mb-10">
			<v-list-item class="drawer_menu ml-5">
				<v-row
					class="ma-0"
					@click="$router.push('/product/brand/jin')"
				>
					<v-col
						cols="4"
						class="pa-0"
					>
						<v-img
							contain
							eager
							width="70"
							:src="require('@/assets/img/natus/logo_brand_jin.svg')"
						/>
					</v-col>
					<span>〉</span>
				</v-row>
			</v-list-item>
			<v-list-group
				v-for="(item, i) in common_menus"
				:key="i"
				v-model="item.active"
				no-action
				class="drawer_menu ml-5"
			>
				<template v-slot:activator>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</template>

				<v-list-item
					v-for="child in item.subMenu"
					:key="child.title"
					class="drawer_menu_sub ml-5"
					:to="child.to"
					link
				>
					<v-list-item-content>
						<v-list-item-title v-text="child.title"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-group>
		</v-list>
		<v-col
			v-if="AUTH_GET_USER_AUTH !== 'GUEST'"
			cols="12"
			class="pa-0 pb-3"
			@click="logout"
		>
			<v-btn
				block
				tile
				text
				clas="py-3"
			>
				<v-icon>mdi-logout</v-icon>
				로그아웃
			</v-btn>
		</v-col>

		<!--		<template v-slot:append>-->
		<!--		</template>-->
	</v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import model from '@/mixins/model'
import common from '@/mixins/common'
import naver_chatbot from '@/mixins/naver_chatbot'

export default {
	name: 'Drawer',
	mixins: [model, common, naver_chatbot],
	data: () => ({
		floatingMenuListItems: [
			// 디자인 개선으로 인한 잠시 주석처리
			{
				title: '최근 본 제품',
				icon: require('@/assets/img/icon/icon_recent_white.svg'),
				to: '/recent',
			},
			// {
			// 	title: '맞춤견적',
			// 	icon: require('@/assets/img/icon/icon_estimate_white.svg'),
			// 	to: '/interior/price/inquire',
			// },
			{
				title: '마루추천',
				icon: require('@/assets/img/icon/icon_camera.svg'),
				to: '/search/maruRecommend',
			},
		],
	}),
	computed: {
		...mapGetters(['AUTH_GET_USER', 'AUTH_GET_USER_AUTH']),
		recentlyRouterPath() {
			return this.$route.path
		},
		menuListItems() {
			let list = [
				{
					title: '동화 톡톡',
					icon: require('@/assets/img/icon/icon_talk_white.svg'),
					to: '/',
				},
				{
					title: '동화 콜센터',
					icon: require('@/assets/img/icon/icon_call_white.svg'),
					to: '/',
				},
			]

			if (this.AUTH_GET_USER_AUTH === 'GUEST')
				list.unshift({
					title: '회원가입',
					icon: require('@/assets/img/icon/icon_user_white.svg'),
					to: '/login/signup',
				})
			else
				list.unshift({
					title: '마이페이지',
					icon: require('@/assets/img/icon/icon_user_white.svg'),
					to: '/user',
				})

			return list
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapActions(['AUTH_ACT_LOGOUT']),
		onClick(link) {
			switch (link.title) {
				case '동화 톡톡':
					this.naver_chatbot_on_click()
					break
				case '동화 콜센터':
					this.common_phoneCall('18992837')
					break
				case '마루추천':
					if (this.recentlyRouterPath === link.to) window.location.reload()
					else this.$router.push(link.to)
					break
				default:
					if (this.recentlyRouterPath === link.to) return this.toggleModel()
					this.$router.push(link.to)
					break
			}
		},
		onUserClick() {
			let select

			if (this.AUTH_GET_USER_AUTH === 'GUEST') select = '/login'
			else select = '/user'

			if (this.recentlyRouterPath === select) return this.toggleModel()

			this.$router.push(select)
		},
		toggleModel() {
			this.model = !this.model
		},
		logout() {
			this.AUTH_ACT_LOGOUT()
			this.$router.push('/login')
		},
	},
}
</script>

<style scoped lang="scss">
.drawer_box {
	z-index: 13;
}

.login_state {
	height: 50px;
	span {
		font-size: $font_normal;
		color: $color_font;
	}
}

.icon_menu {
	&_top {
		background: #3c783a;
	}
	&_bottom {
		background: #326e31;
	}
	&_item {
		&:hover {
			span {
				background: rgba(255, 255, 255, 0.3);
				border-radius: 100px;
			}
		}
	}
	p {
		font-size: $font_normal;
		color: $color_white;
	}
}

.drawer_menu {
	font-size: $font_normal;
	color: $color_black;
	border-bottom: 1px solid $color_font;
	&_sub {
		font-size: $font_mid;
		color: $color_font;
		border-bottom: 1px solid #cfcfcf;
		min-height: 40px !important;
	}
	&_sub:last-child {
		border-bottom: none !important;
	}
}
.logout_btn {
	position: absolute;
	bottom: 10px !important;
}
.v-list-item--link:before {
	background-color: transparent;
}
.v-list-group__header .v-list-item .v-list-item--active {
	border-bottom: 1px solid $color_gray_10;
}
.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
	padding-left: 20px;
}
.v-list-item__content {
	padding-top: 5px;
	padding-bottom: 5px;
}
</style>
